export const agentsApi = {
    getAgents: 'v1/api/agents',
    createAgents: 'v1/api/agents',
    editAgents: 'v1/api/agents',
    deleteAgents: 'v1/api/agents',
    approveAgents: 'v1/api/agentAproval',
    resendInvite:"v1/api/agentAproval/resentInvite?Id=:userId",
    getAgentDoc: 'v1/api/agentDocUpload?agentId=',
    uploadAgentDoc: 'v1/api/agentDocUpload/website?agentId=',
    getAgentLogs: 'v1/api/agentAproval?agentId=:agentId',
    approveAgent: 'v1/api/approveAgent?payId=',
    agentBankDetails: 'v1/api/agents/approveAgentBankDetails?payId=',
    updateLabel: 'v1/api/agentDocUpload/updateLabel?label=:labelName&id=:fileId'
}
