import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private tokenKey = 'access_token';

  setKey(key: string, token: string): void {
    localStorage.setItem(key, token);
  }

  getKey(key: string): string | null {
    return localStorage.getItem(key);
  }

  removeKey(key: string): void {
    localStorage.removeItem(key);
  }

  clearAll(): void {
    localStorage.clear();
  }

  isAuthenticated(): boolean {
    const token = this.getKey(this.tokenKey);
    return !!token;
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('user_details'));
  }

  setUserMailDetails(mailDetails) {
    localStorage.setItem('currentUserMailDetails',JSON.stringify(mailDetails))
  }

  getUserMailDetails() {
    return JSON.parse(localStorage.getItem('currentUserMailDetails'))
  }
}
