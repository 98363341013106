import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeURIComponent'
})
export class DecodeURIComponentPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }
    try {
      return decodeURIComponent(value);
    } catch (error) {
      console.error('Error decoding URI component', error);
      return value;  // If decoding fails, return the original value
    }
  }

}
