export const inhouseCountryApi={
    getCountryList: "v1/api/country",
    getCountryById: "v1/api/country?country=",
    addCountry: "v1/api/country",
    updateCountry: 'v1/api/country?Id=',
    deleteCountry: 'v1/api/country?Id=',
    getStateList: "v1/api/states",
    addState: "v1/api/states",
    updateState: 'v1/api/states?Id=',
    deleteState: 'v1/api/states?Id=',
    getStateByCountry:"v1/api/states?countryId=",
    getUniversityCountries: 'v1/api/universityCountry',
    getRegions: 'v1/api/regions',
    addRegions: 'v1/api/regions',
    updateRegions: 'v1/api/regions?Id=',
    deleteRegions: 'v1/api/regions?Id=',
    getContinent: 'v1/api/continents',
    addContinent: 'v1/api/continents',
    updateContinent: 'v1/api/continents?Id=',
    deleteContinent: 'v1/api/continents?Id=',
    getCountriesTerritory: 'v1/api/country/territory',
    getCountriesById: 'v1/api/country/getById?id=',
    addReligious: 'v1/api/religious',
    getReligiousList: 'v1/api/religious',
    updateReligious: 'v1/api/religious?Id=:courseID',
    getAutomationById: 'v1/api/religious?id=:courseID'
}