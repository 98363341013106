import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private titleService: Title
  ) {
    this.initializeTitleTracking();
  }

  // Method to initialize title tracking
  private initializeTitleTracking(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Call to set the title based on route data
      this.setTitleOnNavigationChange(this.activatedRoute);
    });
  }

  // Method to update title on route navigation change (also when component is refreshed)
  private setTitleOnNavigationChange(route: ActivatedRoute): void {
    const title = this.getTitle(route);
    if (title) {
      this.titleService.setTitle(title);
    }
  }

  // Method to recursively get the title from route data
  private getTitle(route: ActivatedRoute): string {
    let title = 'Crizac - Making Education Easy';

    const recursiveRouteTitle = (currentRoute: ActivatedRoute): string => {
      // Check if the title exists in route data
      if (currentRoute.snapshot.data && currentRoute.snapshot.data['title']) {
        return currentRoute.snapshot.data['title'];
      }

      // If no title in this route, check the child route
      if (currentRoute.firstChild) {
        return recursiveRouteTitle(currentRoute.firstChild);
      }

      return title;
    };

    // Get title or return a fallback title if not found
    title = recursiveRouteTitle(route);
    return title; // Return a default title if no title is found
  }

  // Manually set title if needed
  public setCustomTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  // Get current title
  public getCurrentTitle(): string {
    return this.titleService.getTitle();
  }

  // Example method to prepend or append text to existing title
  public modifyTitle(prefix?: string, suffix?: string): void {
    const currentTitle = this.getCurrentTitle();
    let newTitle = currentTitle;

    if (prefix) {
      newTitle = `${prefix} | ${newTitle}`;
    }

    if (suffix) {
      newTitle = `${newTitle} | ${suffix}`;
    }

    this.setCustomTitle(newTitle);
  }

  // New method for setting the title when app reloads
  public resetTitle(): void {
    this.setTitleOnNavigationChange(this.activatedRoute);  // Set title immediately after app reload
  }
}
