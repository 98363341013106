export const urlRoutes = {
    authentication: 'authentication',
    login: 'login',
    signin: 'signin',
    registration: 'register',
    resetPassword: 'reset-password',
    forgotPassword: 'forgot-password',
    confirmMail: 'confirm-email',
    lockScreen: 'lock-screen',
    logout: 'logout',
    dashboard: 'dashboard',

    // Main layout
    mainLayout: 'admin',

    // Tenant
    tenant: 'tenant',
    createdTenantLink: 'admin/tenant',
    createTenant: 'admin/tenant/create',
    editTenant: 'admin/tenant/edit/:tenantId',

    // SubTenant
    subTenant: 'sub-tenant',
    createdSubTenantLink: 'admin/sub-tenant',
    createSubTenant: 'admin/sub-tenant/create',
    editSubTenant: 'admin/sub-tenant/edit/:subTenantId',

    // Roles
    roles: 'roles',

    // Permission
    permissions: 'permissions',

    // user portal
    users: 'users',
    createUser: 'admin/users/add-user',
    editUser: 'admin/users/edit-user/:userId',
    fullUserPath: 'admin/users',

    //Finance 
    // finance:'university-contract',
    // university_finance:'university-finance',
    financeUniversityContractLink: 'admin/university-contract',
    editUniversityContract: 'admin/finance/university-finance/university_contract/edit_university_contract/:universityId',
    editAgentCommission: 'admin/finance/agent-finance/agent-commission/edit/:agentId',
    // user groups
    users_groups: 'user-groups',
    //Agent
    agent: 'agent',
    approved_agent: 'approved-agents',
    non_approved_agent: 'non-approved-agent',
    pending_bank_approval: 'pending-bank-approval',
    inactive_agent: 'inactive-agent',
    affiliate: 'affiliate',
    non_approved_affilate: 'approvedaffilate',
    counsellor: 'counsellor',

    //ART Application
    ART_Application: 'admin/art/art_assignment',


    //options
    createdOptionsLink: 'admin/options',
    options: 'options',
    options_details: 'details',
    //filters
    filters: 'filters',
    //courses
    courses: 'courses',
    coursesLink: '/admin/courses',
    //coursesFilter
    courseFilter: 'admin/filters',
    //universities
    university: 'university',
    university_entry: 'university_entry',
    university_affliate: 'university_affliate',
    university_process_link: 'university_process_link',
    university_filter_master: 'university_filter_master',
    university_filter_pg: 'university_filter_pg',
    university_filter_pg_add: 'add',
    university_filter_pg_edit: 'edit',
    university_filter_ug: 'university_filter_ug',
    university_filter_ug_add: 'add',
    university_filter_ug_edit: 'edit',
    university_affiliate_add: 'add',
    university_affiliate_edit: 'edit',
    university_cat_cpt_art: 'university_cat_cpt_art',
    university_cat_cpt_art_add: 'add',
    university_cat_cpt_art_edit: 'edit',

    //profile
    profile: 'profile',

    //admin master
    admin_master: 'master',
    admin_company: 'admin-company',
    add_company: 'add_company',
    edit_company: 'edit_company',
    admin_job_designation: 'admin_job_designation',
    admin_employee_designation: 'admin_employee_designation',
    admin_country_section: 'country',
    //link
    link_builder: 'link-builder',
    editLinkBuilder: 'add-edit-link-builder',
    email_master: 'email_master',
    email_smtp: 'smtp',
    smtp_details: 'smtp_details',
    email_template: 'email_template',
    admin_cabin: 'cabin',
    cabin_details: 'details',
    interview_type: 'interview-type',
    interview_type_details: 'details',
    interview_mode: 'interview-mode',
    interview_mode_details: 'details',
    admin_job_role: 'job_role',
    admin_religion: 'religion',
    admin_religion_details: 'details',

    job_role_details: 'details',
    admin_state: 'state',
    admin_state_details: 'details',

    create_email_template: 'details',
    template_send_email: 'send_mail',
    email_template_preview: 'email_template_preview',
    create_dynamic_template: 'create_dynamic_template',
    email_events: 'email_events',
    create_email_events: 'create_email_events',
    application_master: 'application_master',
    app_master_details: 'details',
    app_master_info: 'app_master_info',
    coventry_university_automation_form: 'coventry_university_automation_form',
    greenwich_automation_form: 'greenwich_automation_form',
    eastlondon_automation_form: 'eastlondon_automation_form',
    salford_automation_form: 'salford_automation_form',
    anglia_automation_form: 'anglia_automation_form',
    sheffield_hallam_form: 'sheffield_hallam_form',
    middlesex_form: 'middlesex_form',
    dublin_automation_form: 'dublin_automation_form',
    gcu_automation_form:  'gcu_automation_form',
    dcu_automation_form:  'dcu_automation_form',
    edinburgh_napier_form: 'edinburgh_napier_form',
    hertfordshire_automation_form: 'hertfordshire_automation_form',
    teesside_automation_form: 'teesside_automation_form',
    galway_automation_form: 'galway_automation_form',
    leicester_form: 'leicester_form',
    bangor_form: 'bangor_form',
    nottingham_form: 'nottingham_form',
    limerick_form: 'limerick_form',
    lmu_form: 'lmu_form',
    bcu_form: 'bcu_form',
    gcd_form: 'gcd_form',
    tcd_form: 'tcd_form',
    munster_form: 'munster_form',
    dit_form: 'dit_form',
    tus_form: 'tus_form',
    gmit_form: 'gmit_form',
    lyit_form:'lyit_form',
    ucd_form:'ucd_form',
    leeds_form:'leeds_form',
    university_email: 'university_emails',
    create_university_email: 'details', 
    notification_master: 'notification_master', 
    automation: 'automation',
    automation_details:'details',
    chat_master: 'chat', 
    chat_active: 'chat_active', 
    chat_history: 'chat_history',
    chat_all: 'all-chats', 


    // application status route start 
    // application_status:"application-status",
    // auto_changes:"application-status/auto-changes",
    // secondary_status:"application-status/secondary-status",
    // internal_status:"application-status/internal-status",
    // communication_status:"application-status/communication-status",
    application_status: "application-status",
    primary_status: 'primary-status',
    primary_status_details: 'details',
    secondary_status: 'secondary-status',
    secondary_status_details: 'details',
    auto_change_status: 'auto-change-status',
    auto_change_status_details: 'details',
    communication_status: 'communication-status',
    communication_status_details: 'details',
    internal_status: 'internal_status',
    internal_status_details: 'details',

    //master tools
    master_tools: "master-tool",
    finance: 'finance',
    agent_finance: 'agent-finance',
    agent_commission: 'agent-commission',
    agent_global_commission: 'agent-commission-global',
    // cas_master: 'cas-master',
    add_agent_commission: 'add',
    edit_agent_commission: 'edit',
    university_finance: 'university-finance',
    invoice_finance: 'invoice-finance',
    create_invoice: 'create-invoice',
    unpaid_invoice: 'unpaid-invoice',
    paid_invoice: 'paid-invoice',
    cancel_invoice: 'cancel_invoice',
    university_contract: 'university_contract',
    add_university_contract: 'add_university_contract',
    edit_university_contract: 'edit_university_contract',
    cat: 'cat',
    cat_app_recived: 'cat-app-received',
    cat_all_case: 'cat-all-case',
    cat_my_case: 'cat-my-case',
    cat_coar: 'coar',
    cat_app_status: 'cat-app-status',
    cat_assign_app: 'cat-assign-app',
    cpt_assign_app: 'cpt-assign-app',
    cpt_app_status: 'cpt-app-status',
    cat_owner_status: 'cat-owner-status',

    art_assignment: 'art_assignment',
    art: 'art',
    art_assignment_status: 'art-assignment-status',

    cpt: 'cpt',
    cpt_application: 'assign-application',
    cpt_assign_application: 'assign-application-status',
    cpt_document_verification: 'document-verification',
    cpt_processed_application: 'processed-application',

    country: 'country',
    admin_country: 'country',
    //InterviewMaster
    cct: 'cct',
    cct_interview_master: 'interview-master',
    cct_interview_request: 'interview-request',
    cct_quality_master: 'quality-master',
    cct_view_interview: 'view-interview',
    add_request_details: 'add-request-details',
    cct_interview_assign: 'assign-interview',
    cct_assign_interview: 'assign-interview-status',
    feedback_coventry_form: 'feedback-coventry-form',
    feedback_form: 'feedback-form',

    //University Communication
    university_communications: 'university-communication',
    mail_box: 'mail-box',
    read: 'read',
    mmr_team: 'mmr_team',
    //mailbox
    compose_mail: 'compose',
    //region routes
    admin_regions: 'regions',
    regions_details: 'details',
    //continent routes
    admin_continents: 'continents',
    continents_details: 'details',
    upload_doc_restrictions: 'upload_doc_restrictions',
    upload_doc_restrictions_details: 'details',

    //Notice Board
    notice_board: 'notice-board',
    admin_notice_board: 'admin-notice-board',
    frontend_notice_board: 'frontend-notice-board',
    frontend_notice: 'frontend-notice',
    frontend_webinar_notice: 'frontend-webinar',
    // add_admin_notice_board: 'add-admin-notice-board',

    board: 'board',
    board_details: 'details',
    marksheet_verfication: 'marksheet-verfication',
    marksheet_verfication_details: 'details',
    student_prev_qualification: 'student-prev-qualification',
    student_prev_qualification_details: 'student-prev-qualification-details',

    add_admin_notice_board: 'add',
    add_frontend_notice: 'add-frontend-notice',
    add_frontend_webinar: 'add-frontend-webinar',
    editAdminNotice: 'admin/notice-board/admin-notice-board/edit/:noticeid',
    editFrontendNotice: 'admin/notice-board/frontend-notice-board/frontend-notice/edit/:noticeid',
    editWebinarNotice: 'admin/notice-board/frontend-notice-board/frontend-webinar/edit/:noticeid',
    edit_admin_notice: 'edit',
    edit_webinar_notice: 'edit',
    edit_frontend_notice: 'edit',
    //seprate mail read
    mail_details: 'mail-details',

    //form master
    form_master: 'form-master',
    forms_details: 'details',
    coventry_form: 'coventry',
    aru_form: 'aru',
    greenwich_form: 'greenwich',
    // my drive
    file_manager: 'file-manager',
    my_drive: 'my-drive',
    important: 'important',
    shared_with_me: 'shared-with-me',
    shared_by_me: 'shared-by-me',
    folders: 'folders',
    // cas master
    cas_master: 'cas-master',
    cas_master_details: 'details',
    cas_master_info: 'cas_master_info',
    // confirmation awaited
    confirmation_awaited: 'confirmation_awaited',
    confirmation_awaited_details: 'details',
    confirmation_awaited_info: 'confirmation_awaited_info',
    // ready payment
    ready_to_claim: 'ready_to_claim',
    //automation-log
    automation_log: 'automation-log',
};
